import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./ToAnLuxPayTransferForm.module.css";
import {Popover} from "antd";
import info from "../../assets/img/personalAccount/information.svg";
import CustomDropdown from "../ui/CustomDropdown/CustomDropdown";
import MyInput from "../ui/MyInput/MyInput";
import classnames from "classnames";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Persist} from "formik-persist";
import {motion} from "framer-motion";
import * as yup from "yup";
import CustomDropdownForAccounts from "../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {useAccountsForSelect} from "../../hooks/useAccountsForSelect";
import invoice from "../../assets/img/personalAccount/invoice.svg"
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Plus} from "../../assets/img/personalAccount/plus.svg";
import attachedDoc from "../../assets/img/personalAccount/document.svg";
import delete_document from "../../assets/img/personalAccount/delete_doc.svg";
import {useAppSelector} from "../../hooks/redux";

import {
    CheckAvailableAccountsByEmail, CheckAvaliableAccountByWalletId,
    CheckAvaliableAccountsByPhone,
    CurrencyHelper,
    ResetFieldByTransferMethod
} from "./Helpers";
import CustomModal from "../ui/CustomModal/CustomModal";
import { getFeesForTransaction } from '../../store/reducers/ActionCreators';
import { useDispatch } from 'react-redux';

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
    isButtonDisable: boolean | undefined
    setIsButtonDisabled: Dispatch<React.SetStateAction<boolean>>
}

const ToAnLuxPayTransferForm = (props: PropsType) => {

    const [currency, setCurrency] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [isMobile, setIsMobile] = useState(false)
    const [isAccountWrong, setIsAccountWrong] = useState<boolean | null>(false)
    const [isAvailableAccountExists, setIsAvailableAccountExists] = useState<boolean | null>(true)
    const [isListOfAccounts, setIsListOfAccounts] = useState()

    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)

    const accountsList = useAccountsForSelect().filter((account: any) => account.accountMainIban !== "ALL")
    const token = useAppSelector(state => state.userReducer.userToken)
    const transferMethods = [
        'Wallet Id',
        'Email',
        'Phone number',
    ]

    const accountType = [
        'Personal',
        'Business',
    ]

    const dispatch = useDispatch()

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)

        }
        return () =>  {props.setIsButtonDisabled(false)}
    }, [window.innerWidth])

    const validationSchema = yup.object({
        accNumberFrom: yup.string().required('Required field'),
        accNumberTo: yup.string().required('Required field').max(40, 'max 40 symbols'),
        transferMethod: yup.string().required('Required field'),
        accountType: yup.string().test({message: 'Required field',
            test: function (value) {
                const { transferMethod, isListOfAccounts } = this.parent;
                if (isListOfAccounts?.length === 2 && transferMethod === 'Phone number' && transferMethod === 'Email') {
                    return value !== undefined && value !== null && value !== '';
                }
                return true;
            },
        }),
        phoneNumber: yup.string().test({message: 'Required field',
            test: function (value) {
                const { transferMethod } = this.parent;
                if (transferMethod === 'Phone number') return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
        email: yup.string().test({message: 'Required field',
            test: function (value) {
                const { transferMethod } = this.parent;
                if (transferMethod === 'Email') return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
        amount: yup.string()
            .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Invalid format")
            .required('Required field')
            .max(10, 'max 10 digits')
            .test(
                'no-leading-zero',
                'invalid format',
                (value, context) => {

                    const tempArr = context.originalValue.split('')

                    const onlyZero = tempArr.every((item: string) => {
                        if (item === '0') {
                            return true
                        }
                    })

                    if (context.originalValue && onlyZero) {
                        return false
                    } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                        return false
                    }
                    return true
                }
            )
            .test(
                'max-decimal-places',
                'max 2 symbols after .',
                (value) => {
                    const parts = value.split('.')
                    return parts.length === 1 || parts[1].length <= 2
                }
            ),
        templateName: yup.string().max(100, 'max 100 symbols')
    })

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <Formik initialValues={{
                accNumberFrom: '',
                accNumberTo: '',
                email: '',
                phoneNumber: '',
                emailLanguage: 'EN',
                reference: '',
                templateName: '',
                amount: '',
                transferMethod: 'Wallet Id',
                type: 'internal',
                invoice: '',
                purpose: '',
                hiddenAccountNumber: '',
                transferCurrency: '',
                accountType: ''
            }}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={true}
                    onSubmit={async (values, {resetForm}) => {

                        

                        //@ts-ignore
                        const selectedAccount = isListOfAccounts?.find((account: any) => account.clientType === values.accountType.toLocaleLowerCase());

                        if (selectedAccount) {
                            values.hiddenAccountNumber = selectedAccount.number;
                        }

                        let tempTransferMethod: string|null = ''

                        if (values.transferMethod === 'Phone number') {
                            tempTransferMethod = 'phone'
                        }

                        if (values.transferMethod === 'Email') {
                            tempTransferMethod = 'email'
                        }

                        if (values.transferMethod === 'Wallet Id') {
                            tempTransferMethod = 'account'
                        }

                        const tempData = {
                            ...values,
                            transferMethod: tempTransferMethod,
                            accountNumber: accountNumber,
                            transferCurrency: currency,
                            accountId: accountId,
                            accNumberFrom: accountNumber
                        }

                        try {
                                console.log(tempData)
                                //@ts-ignore
                                const response = await dispatch(getFeesForTransaction(token, tempData))
                        
                                props.setTransferData && props.setTransferData({
                                    ...tempData,
                                    fee: response.data.fee,
                                    totalAmount: response.data.totalAmount
                                    })
                                console.log(props.setTransferData)
                                props.setCurrent && props.setCurrent(1)
                            }
                        catch (e) {
                            console.log(`error dispatch`, e)
                        }

                        resetForm({
                            // @ts-ignore
                            values: ''
                        })

                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <CustomModal type="error" isNotify isModalOpen={isAccountWrong} setIsModalOpen={setIsAccountWrong}>
                            <div className={s.modal_content}>
                                <div className={s.popup_subtitle}>
                                    <span className={s.error}>Failed!</span> The transfer cannot be made as currency of
                                    the payer account doesn't equal to currency of the recipient account.
                                </div>
                                <div className={s.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {setIsAccountWrong(false)}}/>
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal type="error" isNotify isModalOpen={!isAvailableAccountExists} setIsModalOpen={setIsAvailableAccountExists}>
                            <div className={s.modal_content}>
                                <div className={s.popup_subtitle}>
                                    <span className={s.error}>Failed!</span> The transfer cannot be made to the specified&nbsp;
                                    {values.transferMethod.toLowerCase()} due to the lack of an account with the recipient.
                                </div>
                                <div className={s.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {setIsAvailableAccountExists(true)}}/>
                                </div>
                            </div>
                        </CustomModal>

                        <div className={s.form_header}>
                            <div className={[s.input_block, s.mobileMargin].join(' ')}>
                                <div className={s.input_label}>
                                    <span className={s.title_label}><span className={s.red}>*</span> Payer account</span>
                                    <Popover content={"Please select the account you wish to make a transfer from."}
                                             color={'#EEEFEF'}
                                             overlayInnerStyle={{
                                                 fontFamily: 'Manrope',
                                                 width: '280px',
                                                 padding: '13px 16px',
                                                 cursor: 'pointer',
                                                 backgroundColor: '#E7F0EC'
                                             }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    placeholder={"Please select the account"}
                                    id="accNumberFrom"
                                    name="accNumberFrom"
                                    isError={errors.accNumberFrom}
                                    touched={touched.accNumberFrom}
                                    setCurrency={setCurrency}
                                    setAccountNumber={setAccountNumber}
                                />
                                {errors.accNumberFrom && touched.accNumberFrom &&
                                    <div className={s.error_message}>{errors.accNumberFrom}</div>}
                            </div>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    <span className={s.title_label}><span className={s.red}>*</span> Transfer Method</span>
                                    <Popover content={"Choose one of the recipient’s identifiers: their Luxpay" +
                                        " account, email address or phone number."}
                                             color={'#EEEFEF'}
                                             overlayInnerStyle={{
                                                 fontFamily: 'Manrope',
                                                 width: '280px',
                                                 padding: '13px 16px',
                                                 cursor: 'pointer',
                                                 backgroundColor: '#E7F0EC'
                                             }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <CustomDropdown
                                    items={transferMethods}
                                    id="transferMethod"
                                    name="transferMethod"
                                    isError={errors.transferMethod}
                                    touched={touched.transferMethod}
                                    
                                />
                            </div>
                        </div>

                        <div className={s.block}>
                            <div className={s.title}>
                                Outgoing Payment Details
                            </div>
                            <div className={s.rowWrapperElements}>


                                <div className={s.input_block}>
                                    {values.transferMethod === 'Wallet Id'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                <span className={s.title_label}><span className={s.red}>*</span> Wallet ID</span>
                                            </div>
                                            <MyInput id={"accNumberTo"}
                                                     name={"accNumberTo"}
                                                     touched={touched.accNumberTo}
                                                     value={values.accNumberTo}
                                                     onChange={handleChange}
                                                     isError={(props.isButtonDisable) || (touched.accNumberTo && !!errors.accNumberTo)}
                                                     placeholder={"Enter recipient's Wallet Id"}
                                            />
                                            {errors.accNumberTo && touched.accNumberTo &&
                                                <div className={s.error_message}>{errors.accNumberTo}</div>}
                                            {props.isButtonDisable &&
                                                <div className={s.error_message}>invalid wallet Id</div>}
                                        </div>
                                    }

                                    {values.transferMethod === 'Email'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                <span className={s.title_label}><span className={s.red}>*</span> Email</span>
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={(props.isButtonDisable) || (touched.email && !!errors.email)}
                                            />
                                            {errors.email && touched.email &&
                                                <div className={s.error_message}>{errors.email}</div>}
                                            {props.isButtonDisable &&
                                                <div className={s.error_message}>invalid email</div>}
                                        </div>
                                    }

                                    {values.transferMethod === 'Phone number'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                <span className={s.title_label}><span className={s.red}>*</span> phone number</span>
                                                <Popover
                                                    content={"Phone number in international format."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        width: '280px',
                                                        padding: '13px 16px',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#E7F0EC'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"phoneNumber"}
                                                     name={"phoneNumber"}
                                                     touched={touched.phoneNumber}
                                                     value={values.phoneNumber}
                                                     onChange={handleChange}
                                                     isError={(props.isButtonDisable) || (touched.phoneNumber && !!errors.phoneNumber)}
                                            />
                                            {errors.phoneNumber && touched.phoneNumber &&
                                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            {props.isButtonDisable &&
                                                <div className={s.error_message}>invalid phone number</div>}
                                        </div>
                                    }

                                </div>
                                <div className={`${s.mobile_row} ${s.mobileOrderAmount}`}>
                                    <div className={classnames(s.input_block, s.short)}>
                                        <div className={s.input_label}>
                                            <span className={s.title_label}><span className={s.red}>*</span> Amount</span>
                                            <Popover
                                                content={"Select a currency and enter the transfer amount.\n" +
                                                    "In the account balance in the selected currency is insufficient, the system " +
                                                    "will automatically offer you to top up using funds in a different currency " +
                                                    "you have on the account."}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Manrope',
                                                    width: '280px',
                                                    padding: '13px 16px',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#E7F0EC'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"amount"}
                                                 name={"amount"}
                                                 touched={touched.amount}
                                                 value={values.amount}
                                                 onChange={handleChange}
                                                 isError={touched.amount && !!errors.amount}
                                        />
                                        {errors.amount && touched.amount &&
                                            <div
                                                className={classnames(s.error_message, s.error_amount)}>{errors.amount}</div>}
                                    </div>

                                    <div className={s.currency_dropdown}>
                                        <div className={s.input_label}>
                                        <span className={`${s.title_label} ${s.currency_mobile_hidden} `}>Currency</span>
                                        </div>
                                        <MyInput
                                            id={"transferCurrency"}
                                            name={"transferCurrency"}
                                            value={currency}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className={`${s.input_block} ${s.mobileOrderPurpose}`} >
                                    <div className={s.input_label}>
                                    <span className={s.title_label}>Purpose</span>
                                    </div>
                                    <MyInput id={"purpose"}
                                             name={"purpose"}
                                             touched={touched.purpose}
                                             value={values.purpose}
                                             onChange={handleChange}
                                             isError={errors.purpose}
                                             placeholder={"Enter your purpose of payment"}
                                             padding='17px 16px'
                                             isMobile={isMobile}
                                    />
                                </div>



                                {
                                    //@ts-ignore
                                    isListOfAccounts?.length > 1 &&
                                    <div className={`${s.input_block} ${s.showForDesk}`}>
                                        <div className={s.input_label}>
                                            <span className={s.title_label}><span className={s.red}>*</span> RECIPIENT ACCOUNT TYPE</span>
                                        </div>
                                        <CustomDropdown
                                            items={accountType}
                                            id="accountType"
                                            name="accountType"
                                            isError={errors.accountType}
                                            touched={touched.accountType}

                                        />
                                        {errors.accountType && touched.accountType &&
                                                <div className={s.error_message}>{errors.accountType}</div>}
                                    </div>
                                }

                             </div>


                        </div>

                        {!props.id && <div className={s.block}>
                            <div className={s.title}>
                                Save the Transfer Details as a Template
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                    <span className={s.title_label}>template Name</span>
                                        <Popover
                                            content={"In order to save this transfer as a template, please enter the " +
                                                "template name."}
                                            color={'#EEEFEF'}
                                            overlayInnerStyle={{
                                                fontFamily: 'Manrope',
                                                width: '280px',
                                                padding: '13px 16px',
                                                cursor: 'pointer',
                                                backgroundColor: '#E7F0EC'
                                            }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id={"templateName"}
                                             name={"templateName"}
                                             touched={touched.templateName}
                                             value={values.templateName}
                                             onChange={handleChange}
                                             isError={errors.templateName}
                                    />
                                    {errors.templateName &&
                                        <div className={s.error_message}>{errors.templateName}</div>}
                                </div>

                                <div className={s.input_block}>

                                </div>
                            </div>
                        </div>}

                        {
                            !props.id &&
                            <div className={s.add_dock_block}>
                                <div className={classnames(s.row_doc, s.flex_start)}>
                                    <div className={s.invoice_img}>
                                        <img src={invoice} alt=""/>
                                    </div>
                                    <div>
                                        <p className={s.invoice_title}>Add an invoice (optional)</p>
                                        <p className={s.invoice_desc}>Appropriate format: JPEG, JPG, PNG, TXT, PDF.</p>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.button_wrapper}>
                                        <TransparentButton
                                            title={"Add file"}
                                            isPersonalAccountBtn
                                            small
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('hiddenInvoice').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept=".png, .jpg, .jpeg, .pdf, .txt"
                                               id={"hiddenInvoice"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setFieldValue("invoice", event.currentTarget.files[0]);
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            values.invoice && <img src={attachedDoc} alt=""/>
                                        }
                                        {
                                            // @ts-ignore
                                            <span className={s.invoice_doc_name}>{values?.invoice?.name}</span>
                                        }
                                        {
                                            // @ts-ignore
                                            values?.invoice?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("hiddenInvoice").value = ""
                                                setFieldValue("invoice", "")
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={s.continue_block}>
                            <div className={s.required}>
                                <div>
                                    <span><span className={s.red}>*</span> required fields.</span>
                                </div>
                            </div>
                            {!props.id && <div className={s.button_wrapper}>
                                <MyBtn
                                    title={"Continue"}
                                    large
                                    type={"submit"}
                                    localDisable={props.isButtonDisable}
                                    isPersonalAccountBtn
                                />
                            </div>}
                        </div>

                        <Persist name="luxpay-transfer-form"/>

                        <CurrencyHelper
                            accountsList={accountsList}
                            setCurrency={setCurrency}
                            hiddenAccountNumberTo={accountNumber}
                        />

                        <CheckAvailableAccountsByEmail
                            name={"hiddenAccountNumber"}
                            accNumberTo={"accNumberTo"}
                            email={values.email}
                            token={token}
                            currency={currency}
                            setIsAvailableAccountExists={setIsAvailableAccountExists}
                            setIsListOfAccounts={setIsListOfAccounts}
                            setIsButtonDisabled={props.setIsButtonDisabled}
                        />

                        <CheckAvaliableAccountsByPhone
                            name={"hiddenAccountNumber"}
                            accNumberTo={"accNumberTo"}
                            phone={values.phoneNumber}
                            token={token}
                            currency={currency}
                            setIsAvailableAccountExists={setIsAvailableAccountExists}
                            setIsListOfAccounts={setIsListOfAccounts}
                            setIsButtonDisabled={props.setIsButtonDisabled}
                        />

                        <CheckAvaliableAccountByWalletId
                            name={"hiddenAccountNumber"}
                            account={values.accNumberTo}
                            transferMethod={values.transferMethod}
                            token={token}
                            currency={currency}
                            setIsAvailableAccountExists={setIsAvailableAccountExists}
                            setIsAccountWrong={setIsAccountWrong}
                            setIsButtonDisabled={props.setIsButtonDisabled}
                        />

                        <ResetFieldByTransferMethod
                            name={"email"}
                            handleReset={() => {}}
                        />
                        <ResetFieldByTransferMethod
                            name={"phoneNumber"}
                            handleReset={() => {}}
                        />
                        <ResetFieldByTransferMethod
                            name={"accNumberTo"}
                            handleReset={() => {}}
                        />

                    </form>
                )}
            </Formik>
        </motion.div>
    );
};

export default ToAnLuxPayTransferForm;
