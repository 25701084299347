import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik, useFormikContext} from "formik";
import s from "./BankTransferFormPersonal.module.css";
import {Popover} from "antd";
import info from "../../assets/img/personalAccount/information.svg";
import CustomDropdown from "../ui/CustomDropdown/CustomDropdown";
import MyInput from "../ui/MyInput/MyInput";
import classnames from "classnames";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Persist} from "formik-persist";
import {motion} from "framer-motion";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import CustomDropdownForAccounts from "../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {ReactComponent as Plus} from "../../assets/img/personalAccount/plus.svg";
import {
    typesList,
    typesListEUR
} from "../../Constants/StaticData"
import {
    CurrencyHelper,
    PaymentTypeHelper,
    getValidationSchema, SwiftCheckerHelper
} from "./FormHelpers/FormHelpers";
import {setPaymentRegionFilter} from "../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useSelector} from "react-redux";
import {selectAccountsByFilter} from "../../store/reducers/TransactionsSlice/selector";
import invoice from "../../assets/img/personalAccount/invoice.svg";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import attachedDoc from "../../assets/img/personalAccount/document.svg";
import delete_document from "../../assets/img/personalAccount/delete_doc.svg";
import DomesticEur from "./FieldsForPaymentTypes/DomesticEur";
import InternationalEur from "./FieldsForPaymentTypes/InternationalEur";
import DomesticGbp from "./FieldsForPaymentTypes/DomesticGbp";
import InternationalGbp from "./FieldsForPaymentTypes/InternationalGbp";
import CustomCheckbox from "../ui/customCheckbox/CustomCheckbox";
import DomesticUsd from "./FieldsForPaymentTypes/DomesticUsd";
import { getFeesForTransaction } from '../../store/reducers/ActionCreators';
import CustomModal from '../ui/CustomModal/CustomModal';

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
    isButtonDisable: boolean | undefined
}

// const AutoSetPaymentTypeHelper: React.FC<{ currency: string }> = ({ currency }) => {
//
//     const { setFieldValue, values } = useFormikContext();
//
//     useEffect(() => {
//         if (currency === 'GBP') {
//             setFieldValue('paymentRegions', 'domestic');
//         }
//     }, [currency, setFieldValue]);
//
//     return null;
// };

    

    

const BankTransferFormPersonal = (props: PropsType) => {

    const [paymentRegion, setPaymentRegion] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [isIbanWrong, setIsIbanWrong] = useState(false)
    const [selectedOptionRecipientType, setSelectedOptionRecipientType] = useState("INDIVIDUAL")
    const [currency, setCurrency] = useState('')


    const token = useAppSelector(state => state.userReducer.userToken)

    const dispatch = useAppDispatch()

    const [isMobile, setIsMobile] = useState(false)
    
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)

    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean | null>(false)

    const [isNotEnoughMoney, setIsNotEnoughMoney] = useState<boolean | null>(false)

    const accountsList = useSelector(selectAccountsByFilter)

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth])

    useEffect(() => {
        dispatch(setPaymentRegionFilter(paymentRegion))
        return () => {
            localStorage.removeItem('transfer-form')
        }
    }, [paymentRegion])

    // useEffect(() => {
    //         if (paymentRegion) {
    //           const type = paymentRegion.recipientType || 
    //                       paymentRegion.recipientDetails?.recipientType;
    //           if (type) {
    //             setSelectedOptionRecipientType(type);
    //             console.log('Setting recipient type to:', type); // Добавьте логирование
    //           }
    //         }
    //       }, [paymentRegion]);

    console.log(paymentRegion)


    // Domestic for USD

    const [paymentRegionOptions, setPaymentRegionOptions] = useState(typesList)

    useEffect(() => {
        if (currency === 'USD') {

            setPaymentRegionOptions(['domestic']);
            setPaymentRegion('domestic')
        } else if (currency !== 'USD') {

            setPaymentRegionOptions(typesList);
        } else {

            setPaymentRegionOptions(typesList);
        }
    }, [currency]);

    // Domestic for USD

    const clearFormByChangeType = (values: any, setValues: (values: any) => void) => {
        const fieldsToKeep = ["payerAccount", "paymentRegions", "recipientType", "type"]; // unresetebleInputs
        
        const clearedValues = Object.keys(values).reduce((acc, key) => {
            // unresetableInputs
            if (fieldsToKeep.includes(key)) {
                //@ts-ignore
                acc[key] = values[key] || ''
            } 
            
            else {
                acc[key] = "";
            }
            return acc;
        }, {} as Record<string, any>);

    
        setValues(clearedValues);
    };

    const getFees = async (obj: any) => {
       console.log(obj)
        const prePareDataForFees = {
            type: obj.type,
            accountId: obj.accountId,
            accNumberFrom: obj.hiddenAccountNumberFrom,
            amount: obj.amount,
            paymentType: obj.paymentRegions,
            currency: obj.transferCurrency,
        }
        const resp = await dispatch(getFeesForTransaction(token, prePareDataForFees))
        
        return resp
    }
    
    return (
        <div>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >

            <CustomModal type="error" isNotify  isModalOpen={isNotEnoughMoney} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> The transfer cannot be made due to insufficient funds.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => setIsNotEnoughMoney(false)}/>
                    </div>
                </div>
            </CustomModal>

                <Formik
                    initialValues={{
                        name: '',
                        surname: '',
                        companyName: '',
                        recipientsIban: '',
                        recipientsSwiftBic: '',
                        reference: '',
                        recipientsAddress: '',
                        recipientCity: '',
                        recipientPostCode: '',
                        recipientCountry: '',
                        bankName: '',
                        bankAddress: '',
                        bankCity: '',
                        bankPostCode: '',
                        bankCountry: '',
                        recipientsAccountNumber: '',
                        sortCode: '',
                        paymentRegions: '',
                        payerAccount: '',
                        amount: '',
                        templateName: '',
                        invoice: '',
                        recipientType: "INDIVIDUAL",
                        type: 'international',
                    }}
                    validationSchema={getValidationSchema(paymentRegion, currency)}
                    validateOnBlur={true}
                    onSubmit={async (values) => {

                        if (isIbanWrong) {
                            return
                        }

                        let tempData = {
                            ...values,
                            transferCurrency: currency,
                            accountId: accountId,
                            hiddenAccountNumberFrom: accountNumber,
                        };                                           
                        try {
                            const feesData = await getFees(tempData)

                            props.setTransferData && props.setTransferData({
                                ...tempData,
                                fee: feesData.data.fee,
                                totalAmount: feesData.data.totalAmount
                            })
                            props.setCurrent && props.setCurrent(1)
                        }
                        catch (e) {
                            console.log(`error :`, e)
                            setIsErrorPopupOpen(true)
                            setIsNotEnoughMoney(true)
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          resetForm,
                          setFieldValue,
                          handleChange,
                          handleSubmit,
                          setValues
                          
                      }) => (

                        <form onSubmit={handleSubmit}>
                            {/*<AutoSetPaymentTypeHelper currency={currency}/>*/}
                            <div className={s.row}>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        <span className={s.labelAccount}><span className={s.red}>*</span> Payer account</span>

                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Manrope',
                                                     width: '280px',
                                                     padding: '13px 16px',
                                                     cursor: 'pointer',
                                                     backgroundColor: '#E7F0EC'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>

                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        placeholder={"Please select the account"}
                                        id="payerAccount"
                                        name="payerAccount"
                                        isError={errors.payerAccount}
                                        touched={touched.payerAccount}
                                        setCurrency={(selectedCurrency) => {
                                            setCurrency(selectedCurrency);

                                        }}
                                        setAccountNumber={setAccountNumber}
                                    />

                                    {errors.payerAccount && touched.payerAccount &&
                                        <div className={s.error_message}>{errors.payerAccount}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        <span className={s.labelAccount}><span
                                            className={s.red}>*</span> Payment type</span>
                                    </div>
                                    <CustomDropdown
                                        customId="paymentRegions"
                                        items={paymentRegionOptions}
                                        placeholder={"Please select the payment type"}
                                        id="paymentRegions"
                                        name="paymentRegions"
                                        isError={errors.paymentRegions}
                                        touched={touched.paymentRegions}
                                        setItem={(value: any) => {
                                            setPaymentRegion(value);
                                            setFieldValue('paymentRegions', value);
                                        }}
                                    />
                                    {errors.paymentRegions && touched.paymentRegions &&
                                        <div className={s.error_message}>{errors.paymentRegions}</div>}
                                </div>

                            </div>

                            <div className={s.block}>

                                {
                                    values.paymentRegions !== "" && currency !== "" &&
                                    <div>
                                        <div className={s.title}>
                                            Outgoing Payment Details
                                        </div>
                                        <div className={classnames(s.input_block, s.recipient_type_block)}>
                                            <div className={s.input_label}>
                                                <span>RECIPIENT TYPE</span>
                                            </div>
                                            <div className={s.flex_label}>
                                                <label className={s.flex_label}>
                                                    <CustomCheckbox
                                                        handleChange={() => {

                                                            clearFormByChangeType(values, setValues)  
                                                            setSelectedOptionRecipientType(
                                                                "INDIVIDUAL"
                                                            );
                                                            setFieldValue(
                                                                "companyName",
                                                                ""
                                                            );
                                                            setFieldValue(
                                                                "recipientType",
                                                                "INDIVIDUAL"
                                                            );

                                                            
                                                        }}
                                                        id="INDIVIDUAL"
                                                        name="INDIVIDUAL"
                                                        isChecked={
                                                            selectedOptionRecipientType ===
                                                            "INDIVIDUAL"
                                                        }
                                                    />
                                                    <span
                                                        className={
                                                            selectedOptionRecipientType ===
                                                            "INDIVIDUAL"
                                                                ? s.type_of_payment_active
                                                                : s.type_of_payment_inactive
                                                        }
                                                    >
                                                        INDIVIDUAL
                                                    </span>
                                                </label>
                                                <label className={s.flex_label}>
                                                    <CustomCheckbox
                                                        handleChange={() => {
                                                            clearFormByChangeType(values, setValues)
                                                            setSelectedOptionRecipientType(
                                                                "BUSINESS"
                                                            );
                                                            setFieldValue(
                                                                "name",
                                                                ""
                                                            );
                                                            setFieldValue(
                                                                "surname",
                                                                ""
                                                            );
                                                            setFieldValue(
                                                                "recipientType",
                                                                "BUSINESS"
                                                            );
                                                        }}
                                                        id="BUSINESS"
                                                        name="BUSINESS"
                                                        isChecked={
                                                            selectedOptionRecipientType ===
                                                            "BUSINESS"
                                                        }
                                                    />
                                                    <span
                                                        className={
                                                            selectedOptionRecipientType ===
                                                            "BUSINESS"
                                                                ? s.type_of_payment_active
                                                                : s.type_of_payment_inactive
                                                        }
                                                    >
                                                        BUSINESS
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                }

                                {/*Domestic (for EUR)*/}
                                {
                                    values.paymentRegions === "domestic" && currency === "EUR" &&
                                    <DomesticEur
                                        values={values}
                                        currency={currency}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        isIbanWrong={isIbanWrong}
                                    />
                                }

                                {/*International (for EUR)*/}
                                {
                                    values.paymentRegions === "international" && currency === "EUR" &&
                                    <InternationalEur
                                        values={values}
                                        currency={currency}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        isIbanWrong={isIbanWrong}
                                    />
                                }

                                {/*Domestic (for GBP)*/}
                                {
                                    values.paymentRegions === "domestic" && currency === "GBP" &&
                                    <DomesticGbp
                                        values={values}
                                        currency={currency}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        isIbanWrong={isIbanWrong}
                                    />
                                }

                                {/*International (for GBP)*/}
                                {
                                    values.paymentRegions === "international" && currency === "GBP" &&
                                    <InternationalGbp
                                        values={values}
                                        currency={currency}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        isIbanWrong={isIbanWrong}
                                    />
                                }

                                {/*domestic (for USD)*/}
                                {
                                    values.paymentRegions === "domestic" && currency === "USD" &&
                                    <DomesticUsd
                                        values={values}
                                        currency={currency}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        isIbanWrong={isIbanWrong}
                                    />
                                }

                                {
                                    values.paymentRegions !== "" && currency !== "" &&
                                    <div className={s.mobile_amount_row}>
                                        <div className={classnames(s.input_block, s.short)}>
                                            <div className={s.input_label}>
                                                <span><span className={s.red}>*</span> Amount</span>
                                                <Popover
                                                    content={"Select a currency and enter the transfer amount.\n" +
                                                        "In the account balance in the selected currency is insufficient, the system " +
                                                        "will automatically offer you to top up using funds in a different currency " +
                                                        "you have on the account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        width: '280px',
                                                        padding: '13px 16px',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#E7F0EC'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"amount"}
                                                     name={"amount"}
                                                     touched={touched.amount}
                                                     value={values.amount}
                                                     onChange={handleChange}
                                                     isError={touched.amount && !!errors.amount}
                                            />
                                            {errors.amount && touched.amount &&
                                                <div
                                                    className={classnames(s.error_message, s.error_amount)}>{`${errors.amount}`}</div>}
                                        </div>

                                        <div className={s.currency_dropdown}>
                                            <MyInput
                                                id="transferCurrency"
                                                name="transferCurrency"
                                                value={currency}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>

                            {!props.id && <div className={s.block}>
                                <div className={s.title}>
                                    Save the Transfer Details as a Template
                                </div>

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            template Name
                                            <Popover
                                                content={"In order to save this transfer as a template, please enter the " +
                                                    "template name."}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Manrope',
                                                    width: '280px',
                                                    padding: '13px 16px',
                                                    backgroundColor: '#E7F0EC',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"templateName"}
                                                 name={"templateName"}
                                                 touched={touched.templateName}
                                                 value={values.templateName}
                                                 onChange={handleChange}
                                        />
                                        {errors.templateName && touched.templateName &&
                                            <div className={s.error_message}>{errors.templateName}</div>}
                                    </div>

                                    <div className={s.input_block}>
                                    </div>
                                </div>
                            </div>}

                            {
                                !props.id &&
                                <div className={s.docs_wrapper}>
                                    <div className={classnames(s.row_doc, s.flex_start)}>
                                        <div className={s.invoice_img}>
                                            <img src={invoice} alt=""/>
                                        </div>
                                        <div>
                                            <p className={s.invoice_title}>Add an invoice (optional)</p>
                                            <p className={s.invoice_desc}>Appropriate format: JPEG, JPG, PNG, TXT,
                                                PDF.</p>
                                        </div>
                                    </div>
                                    <div className={s.attached_file_wrapper}>
                                        <div className={s.button_wrapper}>
                                            <TransparentButton
                                                isPersonalAccountBtn
                                                title={"Add file"}
                                                small
                                                icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                                onClick={(e: any) => {
                                                    e.preventDefault()
                                                    // @ts-ignore
                                                    document.getElementById('hiddenInvoice').click()
                                                }}
                                            />
                                            <input type="file"
                                                   accept=".png, .jpg, .jpeg, .pdf, .txt"
                                                   id={"hiddenInvoice"}
                                                   className={s.hidden_input}
                                                   onChange={(event) => {
                                                       // @ts-ignore
                                                       setFieldValue("invoice", event.currentTarget.files[0]);
                                                   }}
                                            />
                                        </div>
                                        <div className={s.attached_file}>
                                            {
                                                values.invoice && <img src={attachedDoc} alt=""/>
                                            }
                                            {
                                                // @ts-ignore
                                                <span className={s.invoice_doc_name}>{values?.invoice?.name}</span>
                                            }
                                            {
                                                // @ts-ignore
                                                values?.invoice?.name &&
                                                <img onClick={() => {
                                                    // @ts-ignore
                                                    document.getElementById("hiddenInvoice").value = ""
                                                    setFieldValue("invoice", "")
                                                }}
                                                     className={s.delete_doc}
                                                     src={delete_document}
                                                     alt=""
                                                />
                                            }
                                        </div>
                                    </div>


                                </div>
                            }

                            <div className={s.continue_block}>
                                <div className={s.required}>
                                    <div>
                                        <span><span className={s.red}>*</span> required fields.</span>
                                    </div>
                                </div>
                                {!props.id && <div className={s.button_wrapper}>
                                    <MyBtn
                                        title={"Continue"}
                                        large
                                        type={"submit"}
                                        isPersonalAccountBtn
                                        localDisable={props.isButtonDisable}
                                        onClick={() => console.log(errors)}
                                    />

                                </div>}
                            </div>

                            <Persist name="transfer-form"/>

                            <CurrencyHelper
                                accountsList={accountsList}
                                payerAccount={values.payerAccount}
                                setCurrency={setCurrency}
                            />

                            <SwiftCheckerHelper
                                setFieldValue={setFieldValue}
                                setIsIbanWrong={setIsIbanWrong}
                                isAdditionalFields
                                currency={currency}
                            />

                            <PaymentTypeHelper name={"paymentType"}/>

                        </form>
                    )}
                </Formik>
            </motion.div>
        </div>
    );
};

export default BankTransferFormPersonal;
